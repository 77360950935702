import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import "./Main.scss";
import { Link } from "react-router-dom";
import { api } from "../../api";
import { getImageWithUrl } from "../../helpers/image";

const Main = ({ pageName }) => {
    const [types, setTypes] = useState([]);
    const location = useLocation();
    const effectivePageName = location.state?.pageName || pageName;

    useEffect(() => {
        api.goodsApi.getGoodsSubTypes({ type: effectivePageName }).then(resp => {
            setTypes(resp.data);
        });
    }, [effectivePageName]);

    const listUrl = (name) => `/list/${effectivePageName}/${name}`;
    return (
        <div className="main">
            <div className="main__container">
                {types.map((elem, i) => (
                    <Link key={i} to={listUrl(elem.name)}>
                        <div className='main__container_item'>
                            <img src={getImageWithUrl(elem.icon)} alt="icon" />
                            <p>{elem.name}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Main;
