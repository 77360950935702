// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hookahElem {
  max-width: 205px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/HookahElement/HookahElements.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF","sourcesContent":[".hookahElem {\n  max-width: 205px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
