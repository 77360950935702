// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/banquet/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banquet {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top no-repeat;
  background-size: cover;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.banquet__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.banquet__container_item {
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.8);
}
.banquet__container_item_desc {
  color: #FFF;
  font-size: 30px;
}

@media screen and (max-width: 380px) {
  .banquet__container_item_desc {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Banquet/Banquet.scss"],"names":[],"mappings":"AAAA;EACE,iEAAA;EACA,sBAAA;EACA,4BAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAGI;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AADN;AAGM;EACE,WAAA;EACA,eAAA;AADR;;AAUA;EACE;IACE,eAAA;EAPF;AACF","sourcesContent":[".banquet {\n  background: url(\"../../assets/images/banquet/background.png\") top no-repeat;\n  background-size: cover;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 10px;\n\n    a {}\n\n    &_item {\n      width: 100%;\n      min-height: 70px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      border-radius: 10px;\n      background: rgba(0, 0, 0, 0.80);\n\n      &_desc {\n        color: #FFF;\n        font-size: 30px;\n      }\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 380px) {\n  .banquet__container_item_desc {\n    font-size: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
