// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hookahElements {
  margin-top: 45px;
}

.hookahElement {
  border: 2px solid #78EAFF;
  width: 100%;
  height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  background: rgba(51, 51, 51, 0.3);
  box-shadow: 0 0 10px #78EAFF;
  margin-bottom: 20px;
}
.hookahElement__tobaccoType {
  color: #FFFFFF;
}
.hookahElement__tobaccoType_active {
  color: #00d4ff;
}
.hookahElement__desc_price {
  color: #FFF;
  font-family: Lato;
  font-size: 23.532px;
  margin: 50px 0 20px 0;
}
.hookahElement__desc_name {
  z-index: 1;
  color: #FFF;
  font-family: tangak;
  font-size: 23.532px;
  text-align: center;
  margin-bottom: 40px;
}
.hookahElement .basketButton {
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/HookahElement/HookahElement.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,aAAA;EACA,iCAAA;EACA,4BAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;AACJ;AACI;EACE,cAAA;AACN;AAKI;EACE,WAAA;EACA,iBAAA;EACA,mBAAA;EACA,qBAAA;AAHN;AAMI;EACE,UAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;AAJN;AAQE;EACE,UAAA;AANJ","sourcesContent":[".hookahElements {\n  margin-top: 45px;\n}\n\n.hookahElement {\n  border: 2px solid #78EAFF;\n  width: 100%;\n  height: 375px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 10px;\n  background: rgba(51, 51, 51, 0.30);\n  box-shadow: 0 0 10px #78EAFF;\n  margin-bottom: 20px;\n\n  &__tobaccoType {\n    color: #FFFFFF;\n\n    &_active {\n      color: #00d4ff;\n    }\n  }\n\n  &__desc {\n\n    &_price {\n      color: #FFF;\n      font-family: Lato;\n      font-size: 23.532px;\n      margin: 50px 0 20px 0;\n    }\n\n    &_name {\n      z-index: 1;\n      color: #FFF;\n      font-family: tangak;\n      font-size: 23.532px;\n      text-align: center;\n      margin-bottom: 40px;\n    }\n  }\n\n  .basketButton {\n    z-index: 1;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
