// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/img/backgroundDop.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additionalServicesList {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top;
  background-size: cover;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.additionalServicesList__container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.additionalServicesList__container_items {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}
.additionalServicesList__container img {
  margin-top: 42px;
}
.additionalServicesList__container_item {
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(23, 23, 23, 0.6) 0%, rgba(23, 23, 23, 0.6) 100%), rgba(255, 255, 255, 0.2);
}
.additionalServicesList__container_item_desc {
  color: #FFF;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 380px) {
  .additionalServicesList__container_item_desc {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AdditionalServicesList/AdditionalServicesList.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;EACA,sBAAA;EACA,4BAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;AACJ;AACI;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,8BAAA;AACN;AAGI;EACE,gBAAA;AADN;AAII;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,iHAAA;AAFN;AAKM;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAHR;;AAYA;EACE;IACE,eAAA;EATF;AACF","sourcesContent":[".additionalServicesList {\n  background: url(\"../../assets/images/img/backgroundDop.png\") top;\n  background-size: cover;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n\n  &__container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n\n    &_items{\n      width: 100%;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      gap: 10px;\n      justify-content: space-between;\n    }\n    a {}\n\n    img {\n      margin-top: 42px;\n    }\n\n    &_item {\n      width: 100%;\n      min-height: 70px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      border-radius: 10px;\n      background: linear-gradient(0deg, rgba(23, 23, 23, 0.60) 0%, rgba(23, 23, 23, 0.60) 100%), rgba(255, 255, 255, 0.20);\n\n\n      &_desc {\n        color: #FFF;\n        font-size: 26px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n      }\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 380px) {\n  .additionalServicesList__container_item_desc {\n    font-size: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
