// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.list__title p {
  color: #1D1D1D;
  font-family: Prosto One;
  font-size: 30px;
}
.list__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
  transition: all 0.1s ease-out;
}
.list__animation {
  margin-top: 13px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.list__animation img {
  border-radius: 48px;
  width: 211px;
  height: 211px;
}

.cardsDop {
  flex-direction: row-reverse;
}

@media screen and (max-width: 400px) {
  .list__cards {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  .list__title p {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/List/List.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,4BAAA;EACA,SAAA;AACF;AAGI;EACE,cAAA;EACA,uBAAA;EACA,eAAA;AADN;AAKE;EACE,aAAA;EACA,eAAA;EACA,8BAAA;EACA,gBAAA;EACA,WAAA;EACA,6BAAA;AAHJ;AAME;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;AAJJ;AAMI;EACE,mBAAA;EACA,YAAA;EACA,aAAA;AAJN;;AASA;EACE,2BAAA;AANF;;AAYA;EAEE;IACE,sBAAA;IACA,mBAAA;IACA,WAAA;EAVF;AACF;AAaA;EACE;IACE,eAAA;EAXF;AACF","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &__title {\n\n    p {\n      color: #1D1D1D;\n      font-family: Prosto One;\n      font-size: 30px;\n    }\n  }\n\n  &__cards {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    margin-top: 10px;\n    width: 100%;\n    transition: all .1s ease-out;\n  }\n\n  &__animation {\n    margin-top: 13px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n\n    img {\n      border-radius: 48px;\n      width: 211px;\n      height: 211px;\n    }\n  }\n}\n\n.cardsDop {\n  flex-direction: row-reverse\n}\n\n\n//media\n\n@media screen and (max-width: 400px) {\n\n  .list__cards {\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 360px) {\n  .list__title p {\n    font-size: 25px;\n  }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
