// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.basketIcon {
  height: 32px;
  width: 32px;
  position: relative;
  cursor: pointer;
}
.basketIcon_num {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 21px;
  width: 21px;
  background-color: #F2994A;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.264px; /* 193.87% */
  text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/components/BasketBtn/BasketIcon.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;AACF;AAAE;EACE,kBAAA;EACA,SAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA,EAAA,YAAA;EACA,yBAAA;AAEJ","sourcesContent":[".basketIcon{\n  height: 32px;\n  width: 32px;\n  position: relative;\n  cursor: pointer;\n  &_num{\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    height: 21px;\n    width: 21px;\n    background-color: #F2994A;\n    border-radius: 50%;\n    border: 1px solid #fff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #FFF;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 23.264px; /* 193.87% */\n    text-transform: uppercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
