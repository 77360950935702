// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginActiveBanner {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginActive {
  width: 100%;
  margin-top: -120px;
  border-radius: 31.724px;
  background: var(--Orange, #F2994A);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 270.713px;
}
.loginActive p {
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 16.92px;
  font-style: normal;
  font-weight: 700;
  line-height: 23.264px;
  text-transform: uppercase;
  margin-top: -4px;
  max-width: 250px;
}
.loginActive img {
  margin-top: -27px;
}`, "",{"version":3,"sources":["webpack://./src/components/RegisterActive/RegisterActive.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,kCAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;EACA,yBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,iBAAA;AAAJ","sourcesContent":[".loginActiveBanner {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.loginActive {\n  width: 100%;\n  margin-top: -120px;\n  border-radius: 31.724px;\n  background: var(--Orange, #F2994A);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 270.713px;\n\n  p {\n    color: #FFF;\n    text-align: center;\n    font-family: Montserrat;\n    font-size: 16.92px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 23.264px;\n    text-transform: uppercase;\n    margin-top: -4px;\n    max-width: 250px;\n  }\n\n  img {\n    margin-top: -27px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
