// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/hookah/hookah-still.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hookah {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top center;
  background-size: cover;
  position: relative;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.hookah__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  z-index: 1;
}
.hookah__container_title {
  display: block;
  position: relative;
  z-index: 1;
  color: #FFF;
  font-family: Prosto One;
  font-size: 30px;
  text-align: center;
}

@media screen and (max-width: 393px) {
  .hookah__container p {
    font-size: 22px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Hookah/Hookah.scss"],"names":[],"mappings":"AAAA;EACE,8DAAA;EACA,sBAAA;EACA,kBAAA;EACA,4BAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;AACJ;AACI;EACE,cAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;AACN;;AAOA;EACE;IACE,eAAA;EAJF;AACF","sourcesContent":[".hookah {\n  background: url(\"../../assets/images/hookah/hookah-still.png\") top center;\n  background-size: cover;\n  position: relative;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-size: cover;\n    z-index: 1;\n\n    &_title {\n      display: block;\n      position: relative;\n      z-index: 1;\n      color: #FFF;\n      font-family: Prosto One;\n      font-size: 30px;\n      text-align: center;\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 393px) {\n  .hookah__container p {\n    font-size: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
